//FontAwesome
@import '~@fortawesome/fontawesome-free/css/all.css';

//Bulma
@import '~bulma';

//Bootstrap
@import 'bootstrap';

//Datepicker
@import '~@chenfengyuan/datepicker/dist/datepicker.min.css';

@import "scratchcard";
@import "heinz";

.logo {
  width: 176px;
  height: 55px
}


.navbar-item {
  color: #808080;
  font-size: 1.5rem;
  text-align: right;
}

.dropdown-menu {
  right: 0;
}

.navbar-end {
  margin-right: 50px;
}

.navbar-item img {
  max-height: 3.5rem;
}

.container-xxl {
  max-width: 1600px !important;
}

.landing-wrapper {
  margin: 25px 0 75px 0
}

.catchphrase-wrapper {
  margin-bottom: 50px;
}

.catchphrase {
  font-size: 3.8rem;
  text-align: center;
  padding: 0.7rem 0;
}

.product-shot {
  margin: 5px 15%;
  max-width: 70%;
}

.tomato-image {
  margin: 40px -30%;
  max-width: 160%;
}

.winner-text {
  font-size: 6em;
}

.thankyou-text {
  font-size: 6em;
}

.thankyou-subtext {
  font-size: 4em;
}

@media (max-width: 768px) {
  .logo {
    width: 90px;
    height: 37px
  }

  .catchphrase-wrapper {
    margin-bottom: 0;
  }

  .landing-wrapper {
    margin: 0;
  }

  .catchphrase {
    padding: 0;
  }

  .product-shot {
    margin: 25px 15%;
  }

  .tomato-image {
    margin: 20px 0;
    max-width: 100%;
  }

  .winner-text {
    font-size: 3em;
  }

  .thankyou-text {
    font-size: 3em;
  }

  .thankyou-subtext {
    font-size: 2em;
  }
}

b {
  color: #e1b741;
}

#example_receipt {
  max-width: 70%;
}

.file-icon {
  margin-right: 0 !important;
}

.center {
  position: absolute;
  left: 50%;
  top: 45%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.text-color-heinz p, .text-color-heinz h1, .text-color-heinz h2, .text-color-heinz h3, .text-color-heinz h4, .text-color-heinz h5, .text-color-heinz h6, .text-color-heinz label {
  color: rgb(166, 25, 46);
}

.landing-wrapper .row {
  --bs-gutter-x: 0 !important;
}