@font-face {
  font-family: 'heinzlabel';
  src: url('/fonts/HeinzLabel-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
.mobile {
  display:block;
}
html {
  margin:0;
  padding:0;
  display: flex;
  flex-direction: column;
  background:rgb(166,25,46);
}

body {
  font-family:Helvetica,sans-serif;
  padding:0;
  margin:0 0 2.3rem 0;
  background:#b70b29;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  min-height: 100vh;
  flex-direction: column;
}

div#canvas {
  flex: 1 0 auto;
}
body,h1,h2,h3,h4,h5,h6,p,ol,ul {
  margin:0;
  padding:0;
  font-weight:normal;
  font-family:heinzlabel;
  line-height:1;
  color:#000;
}
.header {
  background-color:#ffffff;
}
img.lang-img {
  max-height:1rem;
}
.navbar-end a {
  padding-left:4px;
  padding-right:4px;
}
.header ul {
  font-family: heinzlabel;
  padding:0;
  margin:0;
  list-style:none;
  line-height:1;
  background-color:#ffffff;
  display:flex;
  flex-direction:row;
}
.header ul a {
  transition: .3s;
  display:block;
  padding:.35rem 1rem;
  margin:.35rem;
  border-radius:1rem;
  color:#666666;
}
.header ul a:hover {
  text-decoration:none;
  color:#333;
  background:#eee;

}
.header ul li:last-child {
  margin-left:auto;
}
.navbar {
  padding:0;
}
.navbar-start,
.navbar-menu {
  box-shadow: none;
}
a {
  color:#b9294c;
  text-decoration:underline;
}
a.navbar-item {
  text-decoration: none;
}
a.navbar-item:hover {
  color:#000;
  text-decoration: none;
}
ul#navigation {
}

#kv {
  background:url(/assets/img/kv.jpg) center center no-repeat;
  background-size: cover;
}


form {
  margin:2rem 0;
}
label {
  color:#fff;
  font-family:heinzlabel;
  display:block;
  line-height:1;
  margin:0;
  padding:.2rem;
}
input {
  margin:0 0 .75rem 0;
  width:100%;
  border:none;
  background:#fff;
  border-radius:1rem;
  font-size:14px;
  padding:.35rem 1rem;
}
select {
  margin:0 0 .75rem 0;
  width:100%;
  border:none;
  background:#fff;
  border-radius:1rem;
  font-size:14px;
  padding:.35rem 1rem;
}
:focus {
  outline:0;
}
.footer2 {
  flex:0;
  width: 100%;
  height: 2.3rem;
}
.footer2 ul {
  padding:0;
  margin:0;
  list-style:none;
  line-height:1;
  background-color:#fff;
  display:flex;
  flex-wrap: wrap;
  flex-direction:row;
  justify-content: center
}
.footer2 ul li {
  flex: 1 0 50%;
  text-align:center;
}
.footer2 ul a {
  transition: .3s;
  display:block;
  padding:.35rem 1rem;
  margin:.35rem;
  border-radius:1rem;
  font-size:17px;
  color:#b9294c;
  text-decoration: none;
}
.footer2 ul a:hover {
  text-decoration:none;
  color:#333;
  background:#eee;

}
img.title {
  max-width:80%;
  margin:2rem 0  0 0;
}
img.title2 {
  max-width:80%;
  margin:0;

}


@media (min-width: 768px) {
  .footer2 ul {
    flex-wrap:nowrap;
    flex-direction:row;
  }
  .footer2 ul li {
    flex:1;
  }
}
img {
  max-width:100%;
  height:auto;
}
img.burger {
  margin:4rem 0 0 0;
}


img.etikett {
  margin:2rem 0;

}
#beschriftung {
  position:relative;
  left:0;
  top:0;
  margin:2rem 0 0 0;
  padding-top:8rem;
  width:100%;
  min-height:25rem;
  background:url(/assets/img/etikett.svg) left top no-repeat;
  background-size:contain;
  background-position:50% 0%;
}
#beschriftung p {
  text-align:center;
  font-size:2.4rem;
  font-family: heinzlabel;
  padding:0;
  margin:0;
  color:#000;
  line-height:1;
}
#beschriftung p.upper {
  font-size:3rem;
}
/* #glow {
	background: url(/assets/img/glow.png) no-repeat;
	background-size: cover;
} */
#mitmachen {
  display:flex;
  justify-content: center;
}
#mitmachen a {
  font-family: heinzlabel;
  color:rgb(166,25,46);
  font-size:1.5rem;
  display:block;
  line-height:1;
  padding:.6rem 2rem .4rem 2rem;
  border-radius:2rem;
  background:#fff;
  box-shadow: .2rem .2rem 0px 0px rgba(0,0,0,0.35);
  margin:0 0 1rem 0;
  text-decoration:none;
}
#mitmachen a:hover {
  color:#000;
}
input[type="submit"] {
  font-family: heinzlabel;
  color:rgb(166,25,46);
  font-size:1.5rem;
  display:block;
  line-height:1;
  margin-top:1rem;
  padding:.6rem 2rem .4rem 2rem;
  border-radius:2rem;
  background:#fff;
  box-shadow: .2rem .2rem 0px 0px rgba(0,0,0,0.35);
}
body.home ul li.nav_home a {
  color:rgb(166,25,46);
}
img.title {
  max-width:80%;
  margin:2rem 0  0 0;
}
img.title2 {
  max-width:80%;
  margin:0 0 1rem 0;
}

h1 {
  padding:4rem 0 0 0;
  font-size:32px;
  color:#fff;
  font-family: heinzlabel;
}
h2 {
  font-size:18px;
  color:#fff;
}
h3 {
  padding:2rem 0 1rem 0;
  font-size:22px;
  line-height:1.4;
  color:#fff;
  font-family: heinzlabel;
}
h3.counter {
  text-align:center;
}
h3.counter strong {
  color:#fff;
  padding:.35rem .5rem;
  border-radius:.5rem;
  background:#7f081d;
}
h4 {
  text-align:center;
  padding:1rem 0 0 0;
  font-size:26px;
  color:#fff;
  font-family: heinzlabel;
}
p {
  color:#fff;
  color:rgba(255,255,255,.8);
}
.tnb p {
  padding:0 0 1rem 0;
  font-size:.9rem;
  font-family:Helvetica,sans-serif;
  line-height:1.5;
}
.tnb ol,
.tnb ul {
  margin:0 0 2rem 0;
  font-size:.9rem;
  font-family:Helvetica,sans-serif;
  line-height:1.5;
  color:rgba(255,255,255,.8);

}
.tnb ol ol {
  padding-bottom:1rem;
  padding-left:1.5rem;
  margin-bottom:0;
}
.tnb h1 {
  padding-top:8rem;
  padding-bottom:1rem;
}
.tnb h2 {
  padding:0 0 .5rem 0;

}
p.small {
  margin:0 0 2rem 0;
  text-align: center;
  font-size:11px;
  line-height:1.3;
  color:rgba(255,255,255,.5);
}
p a {
  color:#fff;
}
p a:hover {
  color:#fff;
  text-decoration:underline;
}
ol {

}
ol li {
  color:#fff;
  color:rgba(255,255,255,.8);
}
p.terms {
  float:left;
  line-height:1;
  text-indent:.5rem;
}
input.terms {
  width:auto;
  float:left;
}
img#preview {
  margin:1rem auto 1rem auto;
  max-height:400px;
}
form  {
  font-size:1.4rem;
  font-weight:400;
}
form .label {
  font-weight:400;
  line-height:1.3;
}
form p.help {
  background:#fff;
  color:red;
  font-weight:400;
  font-size:.8rem;
  line-height:1.4;
  font-family: sans-serif;
}

input.small {

  text-align:center;
  width:2.25rem;
}
input.divider {
  margin-right:.5rem;
}
input.pre {
  text-align:center;
  width:3.5rem;
  border:none;
}
.file-label {
  font-size:1rem;
}
#trenner {
  width:100%;
  padding:0 1rem;
}
section {
  background:#fff;
  padding:1.5rem;
  border:1px solid #fff;
  margin:1rem;
  border-radius:.5rem;
}
section h2 {
  font-size:2rem;
  padding:0 0 1rem 0;
  color:rgb(166,25,46);
}
.countdown {
  /* position:relative;
  left:-1rem; */
  transform: rotate(7deg);
  text-align:center;
  font-size:17px;
  padding:2.25rem 0 0 0;
  width:8rem;
  height:8rem;
  line-height:1.2;
  font-family:heinzlabel;
  color:#fff;
  border:2px solid #fff;
  border-radius:99rem;
}

@media (min-width: 576px) {


  .countdown {
    /* left:-2rem; */
    transform: rotate(7deg);
    text-align:center;
    font-size:16px;
    padding:2rem 0 0 0;

    width:8rem;
    height:8rem;
    line-height:1.2;
    font-family:heinzlabel;
    color:#fff;
    border:2px solid #fff;
    border-radius:99rem;
  }

}
input.error {
  background:#ffa3b1;
}


@media (min-width: 768px) {
  #stoerer {
  }
  .mobile {
    display:none;
  }
  body.home {
    background:#b70b29 center top url(/assets/img/kv2.jpg) no-repeat;
    background-size: contain;


  }
  #beschriftung {
    position:relative;
    left:0;
    top:0;
    margin:2rem 0 0 0;
    padding-top:16rem;
    width:100%;
    min-height:45rem;
    background:url(/assets/img/etikett.svg) left top no-repeat;
    background-size:contain;
    background-position:50% 0%;
  }
  #beschriftung p {
    text-align:center;
    font-size:3.2rem;
    font-family: heinzlabel;
    padding:0;
    margin:0;
    color:#000;
    line-height:1;
  }
  #beschriftung p.upper {
    font-size:4rem;
  }

  .countdown {
    transform: rotate(7deg);
    text-align:center;
    font-size:18px;
    padding:2rem 0 0 0;

    width:8rem;
    height:8rem;
    line-height:1.2;
    font-family:heinzlabel;
    color:#fff;
    border:2px solid #fff;
    border-radius:99rem;
  }
}
@media (min-width: 992px) {
  body.home {
    background:#b70b29 top right url(/assets/img/kv2.jpg) no-repeat;
    background-size: contain;
    background-size:106%;
  }

  img.title {
    max-width:100%;
    margin:4rem 0  0rem 0;
  }
  img.title2 {
    max-width:100%;
    margin:1rem 0 2rem 0;
  }
  #beschriftung {
    position:relative;
    left:0;
    top:0;
    margin:2rem 0 0 0;
    padding-top:12rem;
    width:100%;
    min-height:45rem;
    background:url(/assets/img/etikett.svg) left top no-repeat;
    background-size:contain;
    background-position:50% 0%;
  }
  #beschriftung p {
    text-align:center;
    font-size:2.8rem;
    font-family: heinzlabel;
    padding:0;
    margin:0;
    color:#000;
    line-height:1;
  }
  #beschriftung p.upper {
    font-size:3.6rem;
  }
  .countdown {

    transform: rotate(7deg);
    text-align:center;
    font-size:24px;
    padding:2.25rem 0 0 0;

    width:10rem;
    height:10rem;
    line-height:1.2;
    font-family:heinzlabel;
    color:#fff;
    border:2px solid #fff;
    border-radius:99rem;
  }
}

@media(min-width: 1200px) {
  body.home {
    background:#b70b29 top center url(/assets/img/kv.jpg) no-repeat;
    background-size: contain;
    background-size:100%;
  }

  p.small {
    margin-bottom:2rem;
  }
  img.title {
    max-width:80%;
    margin:6rem 0  2rem 0;
  }
  img.title2 {
    max-width:80%;
    margin:1rem 0 2rem 0;
  }

  #beschriftung {
    position:relative;
    left:0;
    top:0;
    margin:2rem 0 0 0;
    padding-top:14rem;
    width:100%;
    min-height:45rem;
    background:url(/assets/img/etikett.svg) left top no-repeat;
    background-size:contain;
    background-position:50% 0%;
  }
  #beschriftung p {
    text-align:center;
    font-size:3.2rem;
    font-family: heinzlabel;
    padding:0;
    margin:0;
    color:#000;
    line-height:1;
  }
  #beschriftung p.upper {
    font-size:4rem;
  }


  .countdown {
    transform: rotate(7deg);
    text-align:center;
    font-size:24px;
    padding:2.25rem 0 0 0;
    margin-left:0;
    width:10rem;
    height:10rem;
    line-height:1.2;
    font-family:heinzlabel;
    color:#fff;
    border:2px solid #fff;
    border-radius:99rem;
  }

}
