/* ----------------------------------------------
* Generated by Animista on 2019-11-20 21:37:4
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation rotate-scale-up
* ----------------------------------------
*/
@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
    transform: scale(2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}
@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
    transform: scale(2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}
.sc__inner {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sc__wrapper {
  display: block;
  width: 50%;
  height: 420px;
  max-width: 1200px;
  margin: 0 25%;
  border: 5px solid white;
}

@media (max-width: 1180px) {
  .sc__wrapper {
    margin: 0;
    width: 100%;
    height: 500px;
  }
}

@media (max-width: 768px) {
  .sc__wrapper {
    height: 380px;
  }
}

@media (max-width: 500px) {
  .sc__wrapper {
    height: 300px;
  }
}

@media (max-width: 380px) {
  .sc__wrapper {
    height: 200px;
  }
}

.sc__container {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
}

.sc__container > img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.sc__container canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.sc__infos {
  text-align: center;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  font-weight: bold;
  font-size: 18px;
}

.inner_html {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(red, yellow, green);
  display: table;
}

.inner_html p {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  -webkit-animation: rotate-scale-up 1s linear infinite both;
  animation: rotate-scale-up 1s linear infinite both;
}